<template>
  <v-col>
    <v-card>
      <v-card-title class="HeaderTitle">
        <v-toolbar density="compact" color="primary">
          <v-toolbar-title> Login </v-toolbar-title>
        </v-toolbar>
      </v-card-title>

      <v-card-text>
        <v-col class="mt-2" v-if="loading == false">
          <v-form ref="LoginEmty">
            <v-text-field
              v-model="Anmeldedaten.Login"
              prepend-inner-icon="mdi-account-outline"
              color="primary"
              variant="outlined"
              density="compact"
              label="Benutzer"
              :rules="[rules.required]"
              @keydown.enter="sendAnmeldedaten()"
            >
            </v-text-field>

            <v-text-field
            class="mt-2"
              v-model="Anmeldedaten.Password"
              prepend-inner-icon="mdi-lock-outline"
              variant="outlined"
              color="primary"
              density="compact"
              label="Passwort"
              type="password"
              :rules="[rules.required]"
              @keydown.enter="sendAnmeldedaten()"
            >
            </v-text-field>
          </v-form>

          <v-row class="ml-2">
            <v-checkbox
            class="mt-1"
              color="primary"
              v-model="LoginMerken"
              density="compact"
              label="Anmeldedaten merken"
            ></v-checkbox>
            <v-spacer></v-spacer>
            <v-btn
              variant="text"
              size="small"
              color="secondary"
              @click="openSendPasswordVergessen()"
            >
              Passwort vergessen?
            </v-btn>
          </v-row>

          <div v-if="LoginFalsch == true">
            <v-alert
              density="compact"
              color="red"
              title="Falsche Anmeldedaten"
              icon="$warning"
              text=" Die eingegebenen Anmeldedaten sind nicht vorhanden oder falsch. "
            >
            </v-alert>
          </div>

          <v-row class="mt-2 mr-2 ml-2" v-if="Versuche > 3">
            <v-text-field
              class="mr-2"
              v-model="Code"
              density="compact"
              variant="outlined"
              label="Code"
              hint="Groß-Kleinschreibung Beachten! "
              persistent-hint
            ></v-text-field>
            <VueClientRecaptcha @getCode="getCaptchaCode" />
          </v-row>
        </v-col>
        <v-row v-if="loading" class="mt-4">
          <Loadingscreen />
        </v-row>
      </v-card-text>

      <v-row class="ma-2">
        <v-btn v-if="loading == false" @click="sendAnmeldedaten()"> Ok </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="loading == false" @click="openRegestrierern()">
          Registrieren</v-btn
        >
      </v-row>
    </v-card>
  </v-col>

  <v-dialog v-model="showRegestrierern" scrollable persistent="">
    <Regestrieren @closeRegestrieren="closeRegestrieren()" />
  </v-dialog>

  <v-dialog v-model="showSendPasswordVergessen">
    <ShowSendPasswordVergessen
      @closeSendPasswordVergessen="closeSendPasswordVergessen()"
    />
  </v-dialog>

  <v-dialog v-model="Fehler">
    <Error />
  </v-dialog>
</template>

<script setup>
import Error from "@/components/main/Errors.vue";
import Regestrieren from "./Regestrieren.vue";
import ShowSendPasswordVergessen from "./SendPasswordVergessen.vue";
import Loadingscreen from "@/components/main/loadingscreen.vue";

import VueClientRecaptcha from "vue-client-recaptcha";

import dbhelper from "@/plugins/dbHelper";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();

const store = useStore();

var Fehler = ref(false);
var loading = ref(false);

var showRegestrierern = ref(false);
var showSendPasswordVergessen = ref(false);

var LoginEmty = ref(null);
var LoginFalsch = ref(false);
var LoginMerken = ref(false);
var Versuche = ref(0);

var Code = ref("");
var captchaCode = ref("");
var getCaptchaCode = (value) => {
  captchaCode.value = value;
};

var rules = ref({
  required: (value) => !!value || "Pflichtfeld",
});

var Anmeldedaten = ref({
  Login: "",
  Password: "",
});

onMounted(() => {
  if ($cookies.isKey("Benutzer") == true) {
    Anmeldedaten.value.Login = $cookies.get("Benutzer");
    if (Anmeldedaten.value.Login.length > 0) LoginMerken.value = true;
  }
  if ($cookies.isKey("Versuche")) {
    Versuche.value = $cookies.get("Versuche");
  }
});

async function sendAnmeldedaten() {
  if (Code.value != captchaCode.value) {
    router.go(0);
    return;
  }

  var test = await LoginEmty.value.validate();

  if (test.valid == true) {
    if (LoginMerken.value == false) {
      $cookies.remove("Benutzer");
    }

    loading.value = true;

    var url = "Login";
    var response = await dbhelper.Login(url, Anmeldedaten.value);
    
    if (response.data.result.erfolgJN == "J") {
      Versuche.value = 0;
      $cookies.set("Versuche", Versuche.value, 0);

      if (LoginMerken.value == true)
        $cookies.set("Benutzer", Anmeldedaten.value.Login, 0);

      store.state.Rechte = response.data.Rechte;
      store.state.Rechte.forEach((element) => {
        if (element == "Reservieren") {
          store.state.RechtReservieren = true;
          store.state.activeTab = 1;
        }
        if (element == "DokumentLaden") store.state.RechtDokumentLaden = true;
      });
     
      store.state.Login = true;
      $cookies.set("Login",store.state.Login, 0);

      store.state.Page = 1;
      loading.value = false;
      return;
    } else {
      if (response.data.result.fehlertext == "FalscherLogin") {
        LoginFalsch.value = true;
        Versuche.value = Versuche.value + 1;
        $cookies.set("Versuche", Versuche.value, 0);
        loading.value = false;
        return;
      } else {
        store.state.FehlerCode = 202;
        store.state.FehlerText = response.data.result.fehlertext;
        Fehler.value = true;
        loading.value = false;
      }
    }
  } else {
    Versuche.value = Versuche.value + 1;
    $cookies.set("Versuche", Versuche.value, 0);

    LoginFalsch.value = true;
  }
}

function openSendPasswordVergessen() {
  showSendPasswordVergessen.value = true;
}
function openRegestrierern() {
  showRegestrierern.value = true;
}
//close functions
function closeSendPasswordVergessen() {
  showSendPasswordVergessen.value = false;
}

function closeRegestrieren() {
  showRegestrierern.value = false;
}
</script>

<style scoped>

</style>


