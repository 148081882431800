<template>
  <v-card class="Liste" variant="outlined">
    <v-card-title class="HeaderTitle">
      <v-toolbar color="primary" density="compact">
        <v-toolbar-title> Dokumente </v-toolbar-title>
      </v-toolbar>
    </v-card-title>

    <v-card-text>
      <v-row class="ma-2">
        <v-select class="mt-2 mr-2" v-model="DokumentArt" :items="DokumentArten" label="Arten" density="compact"
          variant="outlined" @update:modelValue="filterupdate()">
        </v-select>

        <v-spacer> </v-spacer>
        <v-text-field class="mt-2" v-model="Search" append-inner-icon="mdi-magnify" label="Suche" color="primary"
          density="compact" variant="outlined">
        </v-text-field>
      </v-row>

      <v-data-table :headers="BelegeHeader" :items="ListeGefiltert" :search="Search" items-per-page="20"
        items-per-page-text="Dokumente pro Seite">
        <template v-slot:no-data>
          {{ "Keine Dokumente" }}
        </template>

        <template v-slot:[`item.Vorschau`]="{ item }">
          <v-btn @click="openVorschau(item)"> vorschau </v-btn>
        </template>

        <template v-slot:[`item.download`]="{ item }">
          <v-btn @click="Download(item)"> Download </v-btn>
        </template>

        <template v-slot:[`item.Zeitpunkt`]="{ item }">
          {{ ZeitpunktFormat(item) }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>

  <v-dialog v-model="Bildanzeigen">
    <v-card>
      <v-card-title class="HeaderTitle">
        <v-toolbar density="compact" color="primary">
          <v-toolbar-title> Vorschau </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon @click="Bildanzeigen = false">mdi-close</v-icon>
        </v-toolbar>
      </v-card-title>

      <v-card-text> <v-img :src="Bild"></v-img> </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog v-model="Fehler">
    <Error />
  </v-dialog>
</template>

<script setup>
import Error from "@/components/main/Errors.vue";
import dbhelper from "@/plugins/dbHelper";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import moment from "moment";

const router = useRouter();
const route = useRoute();
const store = useStore();

var BelegeHeader = [
  { title: "Bezeichnung", align: "center", key: "Bezeichnung" },
  { title: "Art", align: "cender", key: "Art" },
  { title: "Datenformat", align: "cender", key: "Datenformat" },
  { title: "Zeitpunkt", align: "cender", key: "Zeitpunkt" },
  { title: "Vorschau", align: "center", key: "Vorschau" },
  { title: "Download", align: "center", key: "download" },
];

const Liste = ref([]);
const ListeGefiltert = ref([]);
const Fehler = ref(false);

const Bildanzeigen = ref(false);
const Bild = ref("");
const Search = ref("");
const DokumentArten = ref([]);
const DokumentArt = ref("");

const download=null;

onMounted(async () => {
  var url = "ImportDokumnentenliste";

  DokumentArten.value = store.state.DokumentArten;
  var response = await dbhelper.ImportDokumnentenliste(url);
  if (response.data.erfolgJN == "N") {
    if (response.data.fehlertext == "abgemeldet") {
      store.state.Login = false;

      router.push({ path: "/reservierung/default" });
    }

    if (response.data.fehlertext != "Keine Dokumente vorhanden") {
      Fehler.value = true;
      store.state.FehlerCode = 202;
      store.state.FehlerText = response.fehlertext;
    }
  } else {
    DokumentArt.value = "Alle";
    Liste.value = response.data;
    ListeGefiltert.value = response.data;
    filterupdate();
  }
});

async function openVorschau(item) {
  var url = "GetDokument";

  var response = await dbhelper.getDokument(url, item);
  if (response.data.result.erfolgJN == "J") {
    var Base64string = response.data.Base64;

    var base64Response = await fetch(
      `data:application/pdf;base64,${Base64string}`
    );

    var blob = await base64Response.blob();
    if (item.Datenformat == "pdf") {
      var file = new File([blob], "vorschau", { type: "application/pdf" });

      var url = "";
      url = URL.createObjectURL(file);

      var link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      window.open(link);
    }
    if (item.Datenformat == "png") {
      Bildanzeigen.value = true;
      var file = new File([blob], "Bild", { type: "image/png" });
      Bild.value = URL.createObjectURL(file);
    }
    if (item.Datenformat == "jpeg") {
      Bildanzeigen.value = true;
      var file = new File([blob], "Bild", { type: "image/jpeg" });
      Bild.value = URL.createObjectURL(file);
    }
    if (item.Datenformat == "jpg") {
      Bildanzeigen.value = true;
      var file = new File([blob], "Bild", { type: "image/jpg" });
      Bild.value = URL.createObjectURL(file);
    }
  } else {
    store.state.FehlerCode = 202;
    store.state.FehlerText = response.data.result.fehlertext;
    Fehler.value = true;
  }
}

async function Download(item) {
  var url = "GetDokument";

  var response = await dbhelper.getDokument(url, item);

  if (response.data.result.erfolgJN == "J") {
    var Base64string = response.data.Base64;

    var base64Response = await fetch(
      `data:application/pdf;base64,${Base64string}`
    );

    var blob = await base64Response.blob();

    var url = "";
    var link = document.createElement("a");

    if (item.Datenformat == "pdf") {
      var file = new File([blob], item.Bezeichnung, {
        type: "application/pdf",
      });
      url = URL.createObjectURL(file);
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
    }
    if (item.Datenformat == "png") {
      var file = new File([blob], item.Bezeichnung, { type: "image/png" });
      url = URL.createObjectURL(file);
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
    }
    if (item.Datenformat == "jpeg") {
      var file = new File([blob], item.Bezeichnung, { type: "image/jpeg" });
      url = URL.createObjectURL(file);
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
    }
    if (item.Datenformat == "jpg") {
      var file = new File([blob], item.Bezeichnung, { type: "image/jpg" });
      url = URL.createObjectURL(file);
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
    }
  } else {
    store.state.FehlerCode = 202;
    store.state.FehlerText = response.data.result.fehlertext;
    Fehler.value = true;
  }
}

function filterupdate() {
  ListeGefiltert.value = [];
  Liste.value.forEach((element) => {
    if (
      element.Art == DokumentArt.value ||
      (DokumentArt.value == "Alle" &&
        element.Art != "Unterschrift Kunde" &&
        element.Art != "Unterschrift Lagermitarbeiter" &&
        element.Art != "Unterschrift Werkstattmitarbeiter" &&
        element.Art != "Unterschrift")
    ) {
      ListeGefiltert.value.push(element);
    }
  });
}

function ZeitpunktFormat(item) {
  let Ausgabe= moment(item.Zeitpunkt, 'YYYYMMDD[T]HHmmss').format('DD.MM.YYYY')
  return Ausgabe;
}
</script>

<style scoped>
.Liste {
  margin: 2em;
  max-height: 100% !important;
}
</style>
