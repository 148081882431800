<template>
	<v-col>
		<v-card
			variant="outlined"
			class="ma-3">
			<v-card-title class="HeaderTitle">
				<v-toolbar
					color="primary"
					density="compact">
					<v-toolbar-title> Persönliche Daten </v-toolbar-title>
				</v-toolbar>
			</v-card-title>
			<v-card-text>
				<div v-if="loading == false">
					<v-row class="mr-2 ml-2 mb-2 mt-4">
						<v-text-field
							v-model="$store.state.Benutzerdaten.Firma"
							label="Firma"
							variant="outlined"
							density="compact">
						</v-text-field>
					</v-row>

					<div v-if="FirmaVorhanden == false">
						<v-row class="ma-1">
							<v-combobox
								v-model="$store.state.Benutzerdaten.Anrede"
								:items="AnredeArray"
								label="Anrede"
								variant="outlined"
								density="compact">
							</v-combobox>
						</v-row>

						<v-row class="ma-1">
							<v-text-field
								class="mr-1"
								v-model="$store.state.Benutzerdaten.Vorname"
								label="Vorname"
								variant="outlined"
								density="compact">
							</v-text-field>

							<v-text-field
								v-model="$store.state.Benutzerdaten.Nachname"
								class="ml-2"
								label="Nachname"
								variant="outlined"
								density="compact">
							</v-text-field>
						</v-row>

						<v-row class="ma-1">
							<v-text-field
								v-model="$store.state.Benutzerdaten.WeitererVorname"
								label="Weiterer Vorname"
								variant="outlined"
								density="compact">
							</v-text-field>
						</v-row>
					</div>

					<v-row class="ma-1 hidden-xs">
						<v-text-field
							v-model="$store.state.Benutzerdaten.Telefon"
							class="mr-1"
							label="Telefon"
							variant="outlined"
							density="compact">
						</v-text-field>

						<v-text-field
							v-model="$store.state.Benutzerdaten.Telefon2"
							class="mr-1"
							label="Telefon2 (Optional)"
							variant="outlined"
							density="compact">
						</v-text-field>

						<v-text-field
							v-model="$store.state.Benutzerdaten.Mobil"
							label="Mobil (Optional)"
							variant="outlined"
							density="compact">
						</v-text-field>
					</v-row>

					<v-text-field
						class="hidden-sm-and-up"
						v-model="$store.state.Benutzerdaten.Telefon"
						label="Telefon 1"
						variant="outlined"
						density="compact">
					</v-text-field>

					<v-text-field
						class="hidden-sm-and-up"
						v-model="$store.state.Benutzerdaten.Telefon"
						label="Telefon 2"
						variant="outlined"
						density="compact">
					</v-text-field>

					<v-text-field
						class="hidden-sm-and-up"
						v-model="$store.state.Benutzerdaten.Mobil"
						label="Mobil (Optional)"
						variant="outlined"
						density="compact">
					</v-text-field>
				</div>

				<v-row
					class="mt-2"
					v-if="loading == true">
					<Loadingscreen />
				</v-row>
			</v-card-text>
			<v-row class="ma-2">
				<v-btn @click="showAcceptDialog = true">Ändern</v-btn>
			</v-row>
		</v-card>

		<v-card
			variant="outlined"
			class="ma-3">
			<v-card-title class="HeaderTitle"
				><v-toolbar
					color="primary"
					density="compact"
					><v-toolbar-title>Adresse</v-toolbar-title></v-toolbar
				>
			</v-card-title>
			<v-card-text>
				<v-row
					class="mt-2"
					v-if="loading == true">
					<Loadingscreen />
				</v-row>

				<div v-if="loading == false">
					<v-row class="mr-2 ml-2 mb-2 mt-4">
						<v-text-field
							v-model="$store.state.Benutzerdaten.Strasse"
							class="mr-1"
							label="Strasse"
							variant="outlined"
							density="compact">
						</v-text-field>

						<v-text-field
            class="mr-1"
							v-model="$store.state.Benutzerdaten.Hausnummer"
							label="Hausnummer"
							variant="outlined"
							density="compact">
						</v-text-field>

						<v-text-field
              
							v-model="$store.state.Benutzerdaten.HausNrErg"
							label="Hausnummer Ergänzung"
							variant="outlined"
							density="compact">
						</v-text-field>
					</v-row>

					<v-row class="ma-2">
						<v-text-field
							v-model="$store.state.Benutzerdaten.Plz"
							class="mr-1"
							label="PLZ"
							variant="outlined"
							density="compact">
						</v-text-field>

						<v-text-field
							v-model="$store.state.Benutzerdaten.Ort"
							label="Ort"
							variant="outlined"
							density="compact">
						</v-text-field
					></v-row>

					<v-row class="ma-2">
						<v-text-field
							v-model="$store.state.Benutzerdaten.Land"
							label="Land"
							variant="outlined"
							density="compact">
						</v-text-field>
					</v-row>
				</div>
			</v-card-text>
			<v-row class="ma-2">
				<v-btn @click="showAcceptDialog = true"> Ändern </v-btn>
			</v-row>
		</v-card>
	</v-col>

	<v-dialog
		class="QuestionDialog"
		v-model="showAcceptDialog"
		persistent>
		<v-card>
			<v-card-title class="HeaderTitle">
				<v-toolbar
					density="compact"
					color="primary">
					<v-toolbar-title> Sind sie sicher?</v-toolbar-title>
				</v-toolbar>
			</v-card-title>

			<v-card-text>
				<v-row>
					<v-icon
						class="ma-3"
						color="red"
						size="75">
						mdi-alert
					</v-icon>
					<div class="ma-3">
						Sie sind dabei, ihre Kontaktdaten zu ändern. <br />
						Beachten Sie, dass einige Funktionen nicht mehr zur Verfügung
						stehen, bis ihre Daten erneut von uns geprüft wurden.
						<br />
						<br />
						Wollen sie trotzdem fortfahren?
					</div>
				</v-row>

				<v-row class="ma-2">
					<v-btn
						class="mr-2"
						@click="changeKontaktDaten()"
						>Ja
					</v-btn>
					<v-btn @click="showAcceptDialog = false">Nein</v-btn>
				</v-row>
			</v-card-text>
		</v-card>
	</v-dialog>

	<v-dialog
		v-model="showChangeKontakt"
		persistent>
		<v-card>
			<v-card-title class="HeaderTitle">
				<v-toolbar
					density="compact"
					color="primary">
					<v-toolbar-title> Daten Erfolgreich geändert </v-toolbar-title>
				</v-toolbar>
			</v-card-title>

			<v-card-text>
				<v-col>
					<v-row
						class="ma-2"
						align="center"
						justify="center">
						<v-icon
							color="green"
							size="100">
							mdi-check-circle
						</v-icon>
					</v-row>
					<v-row
						align="center"
						justify="center">
						Sie haben ihre Daten erfogreich geändert.
					</v-row>
					<v-row
						align="center"
						justify="center">
						Bitte warten sie bis wir die geänderterten Daten geprüft haben.
					</v-row>
				</v-col>
				<v-row
					class="ma-2"
					align="center"
					justify="center">
					<v-btn @click="closeDialogErfolg()">Ok </v-btn>
				</v-row>
			</v-card-text>
		</v-card>
	</v-dialog>

	<v-dialog v-model="Fehler">
		<Errors />
	</v-dialog>
</template>

<script setup>
import Loadingscreen from "@/components/main/loadingscreen.vue"
import Errors from "@/components/main/Errors.vue"
import dbhelper from "@/plugins/dbHelper"
import { onMounted, ref } from "vue"
import { useStore } from "vuex"
import { useRouter, useRoute } from "vue-router"

const router = useRouter()
const route = useRoute()
const store = useStore()

var AnredeArray = ref(["Herr", "Frau"])

var loading = ref(false)
var Fehler = ref(false)
var showAcceptDialog = ref(false)
var showChangeKontakt = ref(false)

var FirmaVorhanden = ref(false)

onMounted(async () => {
	loading.value = true
	var url = "GETKontakt"
	var response = await dbhelper.dbload(url)
	response.Data = JSON.parse(response.Data)

	if (response.result.erfolgJN == "J") {
		store.state.Benutzerdaten.Kundennummer = response.KundenNr

		store.state.Benutzerdaten.EMail = response.Data.EMail

		if (response.Data.Firma.length > 0) FirmaVorhanden.value = true
		store.state.Benutzerdaten.Firma = response.Data.Firma
		store.state.Benutzerdaten.Ausweisnummer = response.Data.Ausweisnummer
		store.state.Benutzerdaten.Anrede = response.Data.Anrede
		store.state.Benutzerdaten.Nachname = response.Data.Nachname
		store.state.Benutzerdaten.Vorname = response.Data.Vorname
		store.state.Benutzerdaten.WeitererVorname = response.Data.WeitereVornamen
		store.state.Benutzerdaten.Strasse = response.Data.Strasse
		store.state.Benutzerdaten.Hausnummer = response.Data.HausNr
		store.state.Benutzerdaten.HausNrErg = response.Data.HausNrErg
		store.state.Benutzerdaten.Plz = response.Data.Plz
		store.state.Benutzerdaten.Ort = response.Data.Ort
		store.state.Benutzerdaten.Telefon = response.Data.Telefon1
		store.state.Benutzerdaten.Telefon2 = response.Data.Telefon2
		store.state.Benutzerdaten.Mobil = response.Data.Mobil
		store.state.Benutzerdaten.EMail = response.Data.EMail
		store.state.Benutzerdaten.Land = response.Data.Land
	} else {
		store.state.FehlerCode = 202
		Fehler.value = true
	}

	loading.value = false
})

async function changeKontaktDaten() {
	var url = "changeKontaktDaten"
	var response = await dbhelper.ChangeKontakt(url, store.state.Benutzerdaten)
	if (response.data.result.erfolgJN == "N") {
		if (response.data.result.fehlertext == "abgemeldet") {
			store.state.Login = false
			router.push({ path: "/reservierung/default" })
		} else {
			store.state.FehlerCode = 202
			store.state.FehlerText = response.data.result.fehlertext
			Fehler.value = true
			showAcceptDialog.value = false
		}
	} else {
		store.state.Benutzerdaten.Kundennummer = response.data.Kontakt.KundenNr
		store.state.Benutzerdaten.Firma = response.data.Kontakt.Firma
		store.state.Benutzerdaten.Ausweisnummer =
			response.data.Kontakt.Ausweisnummer
		store.state.Benutzerdaten.Anrede = response.data.Kontakt.Anrede
		store.state.Benutzerdaten.Nachname = response.data.Kontakt.Nachname
		store.state.Benutzerdaten.Vorname = response.data.Kontakt.Vorname
		store.state.Benutzerdaten.WeitererVorname =
			response.data.Kontakt.WeitereVornamen
		store.state.Benutzerdaten.Strasse = response.data.Kontakt.Strasse
		store.state.Benutzerdaten.Hausnummer = response.data.Kontakt.HausNr
		store.state.Benutzerdaten.HausNrErg = response.data.Kontakt.HausNrErg
		store.state.Benutzerdaten.Plz = response.data.Kontakt.Plz
		store.state.Benutzerdaten.Ort = response.data.Kontakt.Ort
		store.state.Benutzerdaten.Telefon = response.data.Kontakt.Telefon1
		store.state.Benutzerdaten.Telefon2 = response.data.Kontakt.Telefon2
		store.state.Benutzerdaten.Mobil = response.data.Kontakt.Mobil
		store.state.Benutzerdaten.EMail = response.data.Kontakt.EMail
		store.state.Benutzerdaten.Land = response.data.Kontakt.Land
		showAcceptDialog.value = false
		showChangeKontakt.value = true
	}
}

function closeDialogErfolg() {
	store.state.Rechte.splice(store.state.Rechte.indexOf("Reservieren"), 2)
	store.state.RechtReservieren = false

	showChangeKontakt.value = false
}
</script>

<style scoped>
.QuestionDialog {
	width: 50%;
}
</style>
