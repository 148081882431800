// Composables
import { createRouter, createWebHistory } from 'vue-router'
import MainPage from "@/components/MainPage.vue"
import ErrorPage from "../components/main/ErrorPage.vue"

import store from "@/Store/index"
import dbhelper from '@/plugins/dbHelper'

import { useFavicon } from '@vueuse/core'

// ACHTUNG Nur den Kunden einkommentieren, der ausgeliefert wird !
//         sonst werden unnötige Dateien mit ausliefert

async function loadSettings() {

  var LKundeDefiniert = false;
  // Hier Kommen die Kunden files
  let Kundenfile = null;
  /*Hier sind die Kundenfiles richtiges einkomentieren */

  // Beim Ausliefern benutzen (nur nötig weil sonst die Pfade für die static files nicht stimmen)
  //
  // ### Diestelmann IT GmbH ###
  //Kundenfile = await import("../Kunden/diestelmann/Diestelmann.json");      
  //import("../KundenJsons/KampLintfort/KampLintfort_KundenConfig.css")   

  // ### KampLintfort ###
  //Kundenfile = await import("../KundenJsons/KampLintfort/Kamp_Lintfort.json");
  //import("../KundenJsons/KampLintfort/KampLintfort_KundenConfig.css")   

  //### NEW ###
  //Kundenfile = await import("../KundenJsons/New/NEW_Netz.json");
  //import("../KundenJsons/New/NEW_Netz_KundenConfig.css")   

  //### Wasserwerke Paderborn ###
   Kundenfile = await import("../KundenJsons/WW_Paderborn/WW_Paderborn.json");
   import("../KundenJsons/WW_Paderborn/WW_Paderborn_KundenConfig.css")   

  // ### Bochum ###

  // ###################################################################################
  // Fürs Debuggen
  //
  //### Kamp Lintfort ###
  //Kundenfile = await import("../KundenJsons/KampLintfort/Kamp_Lintfort_Debug.json");
  //import("../KundenJsons/KampLintfort/KampLintfort_KundenConfig.css");

  //### NEW ###
  //Kundenfile = await import("../KundenJsons/New/NEW_Netz_Debug.json");
  //import("../KundenJsons/New/NEW_Netz_KundenConfig.css")   

  //### Wasserwerke Paderborn ###
/*   Kundenfile = await import("../KundenJsons/WW_Paderborn/WW_Paderborn_Debug.json");
  import("../KundenJsons/WW_Paderborn/WW_Paderborn_KundenConfig.css") */

  // ### Bochum ###
  //Kundenfile = await import("../KundenJsons/Bochum/Bochum_Debug.json");
  //import("../KundenJsons/Bochum/Bochum_KundenConfig.css")

  // ##################################################################################
  //AB HIER NICHTS ÄNDERN

  store.state.AppTitle = Kundenfile.AppTitle;
  document.title = store.state.AppTitle;
  store.state.AppTitleShort = Kundenfile.AppTitleShort;
  store.state.CookieBannerEingeschaltet = Kundenfile.CookieBannerEingeschaltet;
  store.state.ToolbarAnzeigen = Kundenfile.ToolbarAnzeigen;
  store.state.Bankenmodul = Kundenfile.Bankenmodul;
  store.state.FelderNichtAnzeigen = Kundenfile.FelderNichtAnzeigen;
  store.state.VertragsPflichtfelder = Kundenfile.VertragsPflichtfelder;
  store.state.ImpressumVorhanden = Kundenfile.ImpressumVorhanden;
  store.state.ImpressumPath = Kundenfile.ImpressumPath;

  var FaviconIcon = useFavicon();
  FaviconIcon.value = Kundenfile.FaviconIconpath

  store.state.Logo = Kundenfile.Logo
  store.state.LogoBreite = Kundenfile.LogoBreite;
  store.state.LogoHoehe = Kundenfile.LogoHoehe;
  store.state.Zahlungsart = Kundenfile.Zahlungsart;
  store.state.BezahlItems = Kundenfile.BezahlItems;

  //AGB File
  store.state.AGBDisplayname = Kundenfile.AGBDisplayname;
  var AGBpath = Kundenfile.AGBpath;
  store.state.AGBpath = AGBpath;
  // Datenschutzfile
  store.state.DatenschutzDisplayname = Kundenfile.DatenschutzDisplayname;
  var Datenschutzpath = Kundenfile.Datenschutzpath;
  store.state.Datenschutzpath = Datenschutzpath;
  // Sontiges Dokument
  store.state.SonstigesDokumentDisplayname = Kundenfile.SonstigesDokumentDisplayname;
  var SonstigesDokumentpath = Kundenfile.SonstigesDokumentpath;
  store.state.SonstigesDokumentpath = SonstigesDokumentpath;
  //Preisfile
  var PreisFilepath = Kundenfile.PreisFilepath;
  store.state.PreisFile = PreisFilepath;
  //Anleitung

  var Anleitungpath = Kundenfile.Anleitungpath;
  store.state.AnleitungFile = Anleitungpath;

  store.state.NameDisplayName = Kundenfile.NameDisplayName;
  store.state.KontoinhaberDisplayName = Kundenfile.KontoinhaberDisplayName;
  store.state.IBANDisplayName = Kundenfile.IBANDisplayName;
  store.state.BICDisplayName = Kundenfile.BICDisplayName;
  store.state.BankinstitutDisplayName = Kundenfile.BankinstitutDisplayName;

  store.state.KundennummerDisplayName = Kundenfile.KundennummerDisplayName;
  store.state.FuerPersonDisplayName = Kundenfile.FuerPersonDisplayName;
  store.state.FirmaDisplayName = Kundenfile.FirmaDisplayName;
  store.state.AnredeDisplayName = Kundenfile.AnredeDisplayName;
  store.state.NachnameDisplayName = Kundenfile.NachnameDisplayName;
  store.state.VornameDisplayName = Kundenfile.VornameDisplayName;
  store.state.StrasseDisplayName = Kundenfile.StrasseDisplayName;
  store.state.HausnummerDisplayName = Kundenfile.HausnummerDisplayName;
  store.state.HausNrErgDisplayName = Kundenfile.HausNrErgDisplayName;
  store.state.PlzDisplayName = Kundenfile.PlzDisplayName;
  store.state.OrtDisplayName = Kundenfile.OrtDisplayName;
  store.state.TelefonDisplayName = Kundenfile.TelefonDisplayName;
  store.state.Telefon2DisplayName = Kundenfile.Telefon2DisplayName;
  store.state.MobilDisplayName = Kundenfile.MobilDisplayName;
  store.state.EMailDisplayName = Kundenfile.EMailDisplayName;
  store.state.LandDisplayName = Kundenfile.LandDisplayName;

  store.state.GeraeteklassenDisplayName = Kundenfile.GeraeteklassenDisplayName;
  store.state.BauformDisplayName = Kundenfile.BauformDisplayName;
  store.state.KonzessionsgebietDisplayName = Kundenfile.KonzessionsgebietDisplayName;
  store.state.EinsatzstelleDisplayName = Kundenfile.EinsatzstelleDisplayName;
  store.state.VoraussichtlicheDauerDisplayName = Kundenfile.VoraussichtlicheDauerDisplayName;
  store.state.LangzeitmieteDisplayName = Kundenfile.LangzeitmieteDisplayName;
  store.state.ZahlungsartDisplayName = Kundenfile.ZahlungsartDisplayName;
  store.state.AusgabeNameDisplayName = Kundenfile.AusgabeNameDisplayName;
  store.state.AusgabeVornameDisplayName = Kundenfile.AusgabeVornameDisplayName;

  store.state.ServicePointDisplayName = Kundenfile.ServicePointDisplayName;
  store.state.EinsatzortDisplayName = Kundenfile.EinsatzortDisplayName;
  store.state.MitAbwasserDisplayName = Kundenfile.MitAbwasserDisplayName;
  store.state.EntnahmezweckDisplayName = Kundenfile.EntnahmezweckDisplayName;
  store.state.StueckHydrantenschluesselDisplayName = Kundenfile.StueckHydrantenschluesselDisplayName;
  store.state.BemerkungenDisplayName = Kundenfile.BemerkungenDisplayName;
  store.state.VerwaltungEinschalten = Kundenfile.UserVerwaltung;

  store.state.DokumentArten = Kundenfile.DokumentArten;

}

const routes = [
  {
    path: '/reservierung/default',
    component: MainPage,
    beforeEnter: async (to, from) => {

      var url = "LoadSettings";
      var response = await dbhelper.loadSettings(url);
      var Kunde = response.Kunde
      store.state.CompanyName = Kunde;
      store.state.Kunde = Kunde.split(/\s+/).join('');
      loadSettings();
    }
  },

  {
    path: '/reservierung/ResetPage',
    component: MainPage,
    props: true,
    beforeEnter: async (to, from) => {

      var url = "LoadSettings";
      var response = await dbhelper.loadSettings(url);
      
      var Kunde = response.Kunde;
      store.state.CompanyName = Kunde;
      store.state.Kunde = Kunde.split(/\s+/).join('');
      loadSettings();
    }
  },

  // route wenn keine andere route passt dann wirdf automatisch die Error Page aufgerufen (route Protection) 
  { path: '/:pathMatch(.*)*', component: ErrorPage }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
