<template>
  <Maintoolbar />

  <v-row align="center" justify="center" v-if="$store.state.Login == false &&
    $store.state.VerwaltungEinschalten == true &&
    LoginResetPassword == false
  " class="mt-15 mr-10 ml-10">
    <Login />
  </v-row>

  <v-row v-if="$store.state.Login == true && $store.state.Page == 0">
    <Reservierung />
  </v-row>

  <v-row v-if="store.state.VerwaltungEinschalten == false">
    <Reservierung />
  </v-row>

  <v-row class="mt-15" v-if="$store.state.Login == true &&
    $store.state.Page == 1 &&
    $store.state.VerwaltungEinschalten == true
  ">
    <Dokumente />
  </v-row>

  <v-row class="mt-15" v-if="$store.state.Login == true &&
    $store.state.Page == 2 &&
    $store.state.VerwaltungEinschalten == true
  ">
    <Settings />
  </v-row>

  <v-row align="center" justify="center" v-if="$store.state.PasswordResetPage == true" class="mt-15 mr-10 ml-10">
    <PasswordResetPage />
  </v-row>

  <v-dialog v-model="showCookieBanner" persistent>
    <CookieBanner @closeCookieBanner="closeCookieBanner()" />
  </v-dialog>
</template>

<script setup>
import Dokumente from "@/components/Dokumente/Dokumente.vue";
import Reservierung from "@/components/Resservieren/Reservierungspage.vue";
import Login from "./Login/Login.vue";
import Settings from "./Settings/setting.vue";
import PasswordResetPage from "./Login/PasswordVergessen.vue";

import Maintoolbar from "./main/Maintoolbar.vue";
import dbhelper from "@/plugins/dbHelper";
import CookieBanner from "@/components/main/CookieBanner.vue";

import { ref, onMounted, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useTheme } from "vuetify";
import { useRouter, useRoute, onBeforeRouteUpdate } from "vue-router";

const store = useStore();
const theme = useTheme();
const router = useRouter();
const route = useRoute();

var showCookieBanner = ref(false);
var LoginResetPassword = ref(false);

onBeforeMount(() => {
  theme.global.name.value = store.state.Kunde;

  if (store.state.CookieBannerEingeschaltet == true) {
    showCookieBanner.value = true;
  }
});

onMounted(async () => {
  if (route.path == "/reservierung/ResetPage") {
    var url = "CheckResetToken";
    var ResetToken = route.query.ResetToken;
    let response = await dbhelper.checkResetToken(url, ResetToken);
    if (response.data.erfolgJN == "J") {
      store.state.ResetToken = ResetToken;
      LoginResetPassword.value = true;
      store.state.PasswordResetPage = true;
    } else {
      store.state.FehlerCode = 202;
      store.state.FehlerText = response.data.fehlertext;
    }
  }



  if (store.state.RechtReservieren == true) 
    store.state.activeTab = 1;
  store.state.Page = 1;

});

function closeCookieBanner() {
  showCookieBanner.value = false;
}
</script>
