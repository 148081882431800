  <template>
    <v-app-bar color="primary"
      v-if="$store.state.VerwaltungEinschalten == true && $store.state.ToolbarAnzeigen == true">
      <v-toolbar color="primary">
        <template v-slot:prepend>
          <v-img class="ma-2 hidden-xs-and-down" :src="$store.state.Logo" :width="$store.state.LogoBreite"
            :height="$store.state.LogoHoehe" aspect-ratio="16/9">
          </v-img>
        </template>

        <v-row class="ma-2 hidden-xs" v-if="$store.state.Login == false">
          <v-toolbar-title>{{ $store.state.AppTitle }}</v-toolbar-title>
        </v-row>

        <v-row class="ma-2 hidden-sm-and-up" v-if="$store.state.Login == false">
          <v-toolbar-title> {{ $store.state.AppTitleShort }}</v-toolbar-title>
        </v-row>

        <v-toolbar-title v-if="$store.state.Login == true">
          <v-tabs class="hidden-xs" v-model="$store.state.activeTab" center-active show-arrows>
            <v-tab v-if="$store.state.RechtReservieren" @click="$store.state.Page = 0">
              <v-icon class="mr-1">mdi-archive-arrow-up</v-icon>
              Reservieren
            </v-tab>
            <v-tab v-if="$store.state.RechtDokumentLaden == true" @click="$store.state.Page = 1">
              <v-icon class="mr-1">mdi-file-multiple</v-icon>
              Dokumente</v-tab>
            <v-tab @click="$store.state.Page = 2">
              <v-icon class="mr-1">mdi-account-details</v-icon>
              Account
            </v-tab>
            <v-spacer></v-spacer>
            <v-tab v-if="$store.state.Login == true" @click="Ausloggen()">
              <v-icon class="mr-1">mdi-power</v-icon>
              Ausloggen
            </v-tab>
          </v-tabs>

          <v-row class="ma-2 hidden-sm-and-up">
            <v-spacer></v-spacer>
            <v-menu location="bottom">
              <template v-slot:activator="{ props }">
                <v-icon class="mr-4" v-bind="props"> mdi-menu </v-icon>
              </template>
              <v-list class="mobilMenu mt-2 rounded-xl" color="primary">
                <v-list-item v-if="$store.state.RechtReservieren" @click="$store.state.Page = 0">
                  <v-icon>mdi-archive-arrow-up</v-icon>
                  Reservieren
                </v-list-item>
                <v-list-item v-if="$store.state.RechtDokumentLaden == true" @click="$store.state.Page = 1">
                  <v-icon>mdi-file-multiple</v-icon>
                  Dokumente
                </v-list-item>
                <v-list-item @click="$store.state.Page = 2">
                  <v-icon>mdi-account-details</v-icon>
                  Account</v-list-item>
                <v-list-item @click="Ausloggen()"><v-icon>mdi-power</v-icon> Ausloggen</v-list-item>
              </v-list>
            </v-menu>
          </v-row>
        </v-toolbar-title>
      </v-toolbar>
    </v-app-bar>

    <v-app-bar flat v-if="
      $store.state.VerwaltungEinschalten == false &&
      $store.state.ToolbarAnzeigen == true
    ">
      <v-img :src="$store.state.Logo" :width="10" :height="60"> </v-img>
      <v-toolbar-title class="hidden-xs"> {{ store.state.AppTitle }} </v-toolbar-title>
      <v-toolbar-title class="hidden-sm-and-up"> {{ store.state.AppTitleShort }} </v-toolbar-title>
    </v-app-bar>

    <v-dialog v-model="Fehler">
      <Errors />
    </v-dialog>
  </template>

<script setup>
import Errors from "./Errors.vue";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import dbhelper from "@/plugins/dbHelper";
import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();

const store = useStore();
var Fehler = ref(false);

onMounted(async () => {
  let response = await dbhelper.checkSession();
  if (response.data.result.erfolgJN == 'J') {
    store.state.Login = true;
    store.state.Rechte = response.data.Rechte

    store.state.Rechte.forEach((element) => {
      if (element == "Reservieren") {
        store.state.RechtReservieren = true;
        store.state.activeTab = 1;
      }
      if (element == "DokumentLaden")
        store.state.RechtDokumentLaden = true;
    });
  }
  store.state.Titel = "Standrohr";
});

async function Ausloggen() {
  var url = "logout";
  var response = await dbhelper.Logout(url);
  if (response.erfolgJN == "N") {
    store.state.Login = false;
    store.state.activeTab = 1;
    $cookies.remove("Login");
  } else {
    store.state.FehlerCode = 202;
    store.state.FehlerText = "Konnte nicht Abmelden";
    Fehler.value = true;
  }
}
</script>